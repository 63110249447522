import { Modal } from "antd";

import _ from "../../i18n";

import Button from "../common/Button";
import HistoryItem from "./HistoryItem";
import styles from "./FiduciaryModal.module.scss";

const HistoryModal = ({ modalOpen, setModalOpen, fiduciary }) => {
  if (!fiduciary) {
    return null;
  }

  return (
    <Modal
      width="70vw"
      height="50vh"
      closable={false}
      visible={modalOpen}
      footer={null}
      onCancel={(e) => {
        e.stopPropagation();
        setModalOpen(false);
      }}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div className={styles.modal_header}> Historique des modifications</div>
      <div
        className={styles.modal_close}
        onClick={(e) => {
          e.stopPropagation();
          setModalOpen(false);
        }}
      >
        <i className="icon-ttp-close"></i>
      </div>

      <div className={styles.content}>
        <div className={styles.historylist}>
          {!fiduciary.note || 0 === fiduciary.note.length
            ? ""
            : fiduciary.note.map((history, idx) => (
                <HistoryItem key={idx} item={history} />
              ))}
        </div>
      </div>
      <div className={styles.actions}>
        <Button variant="default" onClick={() => setModalOpen(false)}>
          {_("close")}
        </Button>
      </div>
    </Modal>
  );
};

export default HistoryModal;