import { TTP_ONBOARDING_URL, TTP_HOME_URL, URL_HASH_KEY } from "../config";
import CryptoJS from "crypto-js";

import _ from "../i18n";

export const getDefaultLanguage = () => {
  let lng = navigator.language || navigator.userLanguage;
  lng = lng.split("-")[0];
  return ["fr", "en", "nl"].includes(lng) ? lng : "en";
};

export const isServer = !(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

export const redirectToHome = (returnTo = "") => {
  const goto = encodeURIComponent(TTP_ONBOARDING_URL + "/" + returnTo);
  window.location.assign(`${TTP_HOME_URL}?goto=${goto}`);
};

export function validateUrl(url) {
  var re =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  return re.test(String(url).toLowerCase());
}

export function validateEmail(email) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function cleanEmail(email) {
  return email.replace("mailto:", "").trim();
}

export const validateUen = (uen) => {
  const formattedUen = uen.replace(
    /(BE|be)? ?(\d{4})[. ]?(\d{3})[. ]?(\d{3})/,
    "BE $2.$3.$4"
  );
  return /^[a-zA-Z]{2} (\d{4})[. ]?(\d{3})[. ]?(\d{3})$/.test(formattedUen);
};

export const formatUen = (uen) => {
  const _uen = (uen ?? "").replace(/[^a-zA-Z0-9]/, "");

  if (_uen.length !== 12) {
    return uen;
  }

  return _uen.replace(/^([a-zA-Z0-9]{2})(\d{4})(\d{3})(\d{3})$/, "$1 $2.$3.$4");
};

export const obscureEmail = (email) => {
  const [name, domain] = email.split("@");
  return `${name[0]}${new Array(name.length).join("*")}@${domain}`;
};

export function formatHidePhone(number) {
  if (!number) {
    return "";
  }
  let hideNum = [];
  for (let i = 0; i < number.length; i++) {
    if (i < number.length - 2) {
      hideNum.push("*");
    } else {
      hideNum.push(number[i]);
    }
  }
  return hideNum.join("");
}

export function formatPhone(number) {
  if (!number) {
    return "";
  }
  if (number.substr(0, 3) === "+32") {
    let base = number.substr(3);
    let subStr = base.substr(0, 2);

    if (["1", "5", "6", "7", "8"].includes(base.substr(0, 1))) {
      return (
        "+32 (0)" +
        subStr +
        " " +
        base.substr(2, 2) +
        " " +
        base.substr(4, 2) +
        " " +
        base.substr(6, 2)
      );
    }
    if (
      ["2", "3"].includes(base.substr(0, 1)) ||
      ["42", "43", "90", "92", "93", "94", "97", "98", "99"].includes(subStr)
    ) {
      return (
        "+32 (0)" +
        base.substr(0, 1) +
        " " +
        base.substr(1, 3) +
        " " +
        base.substr(4, 2) +
        " " +
        base.substr(6, 2)
      );
    }
    if (["46", "47", "48", "49"].includes(base.substr(0, 1))) {
      return (
        "+32 (0)" +
        base.substr(0, 3) +
        " " +
        base.substr(3, 2) +
        " " +
        base.substr(5, 2) +
        " " +
        base.substr(7, 2)
      );
    }
    if (base.substr(0, 3) === "800" || subStr === "90") {
      return (
        "+32 (0)" +
        base.substr(0, 3) +
        " " +
        base.substr(3, 2) +
        " " +
        base.substr(5, 3)
      );
    }
  } else if (number.substr(0, 3) === "+33") {
    let base = number.substr(3);
    return (
      "+33 (0)" +
      base.substr(0, 1) +
      " " +
      base.substr(1, 2) +
      " " +
      base.substr(3, 2) +
      " " +
      base.substr(5, 2) +
      " " +
      base.substr(7, 2)
    );
  }
  return number;
}
export const getDateLabel = (date) => {
  const d = new Date(date);

  const result = d.toDateString().split(" ");

  const hours =
    parseInt(d.getHours(), 10) < 10 ? "0" + d.getHours() : d.getHours();
  const minutes =
    parseInt(d.getMinutes(), 10) < 10 ? "0" + d.getMinutes() : d.getMinutes();

  return (
    result[2] +
    " " +
    result[1] +
    " " +
    result[3] +
    ", at " +
    hours +
    ":" +
    minutes
  );
};

export const sanitize = function (str) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
  var to = "aaaaaeeeeeiiiiooooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
};

export function sortCategoriesAlphabetically(items, key) {
  return items.slice().sort((a, b) => {
    return a[key].toLowerCase().localeCompare(b[key]);
  });
}

export function getRemainAddCollaborator(officeSize, nbCollaborators) {
  if (!officeSize) {
    return 0;
  }
  const size = officeSize.split("-");
  if (size.length === 2) {
    return parseInt(size[0]) + parseInt(size[1]) - nbCollaborators;
  }
  return 0;
}

export function sanitizeSubdomain(str) {
  const replace_chars = {
    Š: "S",
    š: "s",
    Ð: "Dj",
    Ž: "Z",
    ž: "z",
    À: "A",
    Á: "A",
    Â: "A",
    Ã: "A",
    Ä: "A",
    Å: "A",
    Æ: "A",
    Ç: "C",
    È: "E",
    É: "E",
    Ê: "E",
    Ë: "E",
    Ì: "I",
    Í: "I",
    Î: "I",
    Ï: "I",
    Ñ: "N",
    Ò: "O",
    Ó: "O",
    Ô: "O",
    Õ: "O",
    Ö: "O",
    Ø: "O",
    Ù: "U",
    Ú: "U",
    Û: "U",
    Ü: "U",
    Ý: "Y",
    Þ: "B",
    ß: "Ss",
    à: "a",
    á: "a",
    â: "a",
    ã: "a",
    ä: "a",
    å: "a",
    æ: "a",
    ç: "c",
    è: "e",
    é: "e",
    ê: "e",
    ë: "e",
    ì: "i",
    í: "i",
    î: "i",
    ï: "i",
    ð: "o",
    ñ: "n",
    ò: "o",
    ó: "o",
    ô: "o",
    õ: "o",
    ö: "o",
    ø: "o",
    ù: "u",
    ú: "u",
    û: "u",
    ý: "y",
    þ: "b",
    ÿ: "y",
    ƒ: "f",
  };
  let result = str;
  for (let char in replace_chars) {
    const regex = new RegExp(char, "g");
    result = result.replace(regex, replace_chars[char]);
  }
  result = result
    .replace(/[&]/g, "and")
    .replace(/[@]/g, "at")
    .replace(/[#]/g, "number");
  result = result.replace(/[^\x20-\x7F]*/g, "");
  result = result.replace(/ /g, "");

  result = result.replace(/'/g, "");

  result = result.replace(/[^\w\-.]+/g, "");

  result = result.replace(/[-]+/g, "");
  result = result.replace(/\./g, "");

  return result.toLowerCase();
}
export const decryptWithAES = (ciphertext) => {
  var encryptMethod = "AES-256-CBC";
  var aesNumber = encryptMethod.match(/\d+/)[0];
  var encryptMethodLength = parseInt(aesNumber);

  var json = JSON.parse(
    CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(ciphertext))
  );

  var salt = CryptoJS.enc.Hex.parse(json.salt);
  var iv = CryptoJS.enc.Hex.parse(json.iv);

  var encrypted = json.ciphertext; // no need to base64 decode.

  var iterations = parseInt(json.iterations);
  if (iterations <= 0) {
    iterations = 999;
  }
  var encryptMethodLength = encryptMethodLength / 4; // example: AES number is 256 / 4 = 64
  var hashKey = CryptoJS.PBKDF2(URL_HASH_KEY, salt, {
    hasher: CryptoJS.algo.SHA512,
    keySize: encryptMethodLength / 8,
    iterations: iterations,
  });

  var decrypted = CryptoJS.AES.decrypt(encrypted, hashKey, {
    mode: CryptoJS.mode.CBC,
    iv: iv,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const formatDecimalHours = (decimalHours, withAbr = true) => {
  const hoursLabel = withAbr ? _("hour_abr") : ` ${_("hours")}`;
  const minutesLabel = withAbr ? "min" : ` ${_("minutes")}`;
  const sep = withAbr ? " " : ` ${_("and")} `;

  if (!decimalHours || isNaN(+decimalHours)) {
    return `0${hoursLabel}`;
  }

  let hours = +decimalHours;
  let minutes = 0;

  minutes = hours % 1;
  hours = Math.floor(hours);
  minutes = Math.round(minutes * 60);

  if (minutes === 60) {
    minutes = 0;
    hours++;
  }

  if (minutes > 0 && hours > 0) {
    return `${hours}${hoursLabel}${sep}${minutes}${minutesLabel}`;
  }

  if (minutes > 0) {
    return `${minutes}${minutesLabel}`;
  }

  return `${hours}${hoursLabel}`;
};

export const htmlDecode = (strData) => {
  if (strData && typeof strData === "string") {
    return strData.replace(/&#(\d+);/g, (match, dec) => {
      return String.fromCharCode(dec);
    });
  }
  return "";
};

export const unescapeHtml = (safe) => {
  if (safe && typeof safe === "string") {
    return safe
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&quot;/g, `"`)
      .replace(/&#039;/g, "'");
  }
};

export const purifyString = (string) => {
  return htmlDecode(unescapeHtml(string));
};

