import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";

import { sortCategoriesAlphabetically } from "../../utils";

import { SELECT_STYLES } from "../../config";
import _ from "../../i18n";

export default function Category({
  category,
  language,
  setCategory,
  initialCategories,
}) {
  const customFilter = (option, searchText) => {
    return option.data.name.toLowerCase().includes(searchText.toLowerCase());
  };
  const nameAttr = `name${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const dbCategories = useSelector((state) =>
    sortCategoriesAlphabetically(state.categories.categories, nameAttr)
  );

  let categoryOptions = [];

  useEffect(() => {
    if (dbCategories.length > 0 && initialCategories.length > 0 && !category) {
      const nameAttr = `name${
        language.charAt(0).toUpperCase() + language.slice(1)
      }`;
      let tab = [];
      initialCategories.map((id) => {
        dbCategories.forEach((c) => {
          if (c.id === id) {
            const categoryName =
              c[nameAttr] || c["nameFr"] || c["nameEn"] || c["nameNl"];
            tab.push({
              id: c.id,
              name: categoryName,
            });
          }
        });
      });
      setCategory(tab);
    }
  }, [dbCategories, initialCategories]);

  dbCategories.map((category, index) => {
    const categoryName =
      category[nameAttr] ||
      category["nameFr"] ||
      category["nameEn"] ||
      category["nameNl"];
    categoryOptions.push({
      id: category.id,
      name: categoryName,
    });
  });

  return (
    <Select
      styles={SELECT_STYLES}
      options={categoryOptions}
      filterOption={customFilter}
      placeholder={_("select_category")}
      value={category ? category : []}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      onChange={setCategory}
      isMulti
      noOptionsMessage={() => _(`no_options`)}
    />
  );
}
