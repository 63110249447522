import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { HeaderUA } from "tamtam-components";

import { TTP_ONBOARDING_URL, APP_ENV } from "../../config";
import { setLanguage, setCurrentPortal, setNavCommunity } from "../../store";
import { logout } from "../../utils";
import _ from "../../i18n";

const Menu = () => {
  const lng = useSelector((state) => state.params.language);
  const currentPortal = useSelector((state) => state.params.currentPortal);
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const { loggedAs, loggedAsPost, user } = auth;
  const dispatch = useDispatch();
  const [portalSwitch, setPortalSwitch] = useState(null);
  const navigate = useNavigate();
  const privacyRubrics = useSelector((state) => state.privacy.items);
  const [onBoardProgress, setOnBoardProgress] = useState(0);
  const [showOnBoardProgress, setShowOnBoardProgress] = useState(false);

  useEffect(() => {
    if (
      fiduciary &&
      fiduciary.uaStatus === "VALIDATED" &&
      fiduciary.onBoardingStep === "COMPLETE"
    ) {
      let tab = {
        items: [],
        onChange: (e) => {
          dispatch(setCurrentPortal(e.key));
          if (e.key === "PUBLIC") {
            window.location.href = "http://" + e.url;
          } else {
            navigate(e.url);
          }
        },
      };

      if (!["/account-activation"].includes(location.pathname)) {
        if (loggedAs !== "CLIENT") {
          if (["LEGAL_REPRESENTATIVE", "MANAGER"].includes(loggedAsPost)) {
            tab.items.push({
              key: "SETTINGS",
              label: _("configuration"),
              url: "/settings",
            });
            if (
              fiduciary.hubPreferences &&
              fiduciary.hubPreferences.portals?.public.enabled &&
              fiduciary.subdomain
            ) {
              tab.items.push({
                key: "PUBLIC",
                label: _("public_portal"),
                url: `${fiduciary.subdomain}.${TTP_ONBOARDING_URL.replace(
                  /(^\w+:|^)\/\//,
                  ""
                )}`,
              });
            }
          }
          if (fiduciary.hubPreferences && fiduciary.hubPreferences.portals) {
            if (fiduciary.hubPreferences.portals.collaborator.enabled) {
              tab.items.push({
                key: "COLLABORATOR",
                label: _("collaborator_portal"),
                url: "/collaborator",
              });
            }
            if (
              ["LEGAL_REPRESENTATIVE", "MANAGER"].includes(loggedAsPost) &&
              fiduciary.hubPreferences.portals.client?.enabled
            ) {
              tab.items.push({
                key: "CLIENT",
                label: _("client_portal"),
                url: "/client",
              });
            }
          }
        } else {
          tab.items.push({
            key: "CLIENT",
            label: "Portail client",
            url: "/client",
          });
        }
      }
      setPortalSwitch(tab);
    }
    if (fiduciary) {
      if (["/join", "/welcome"].includes(location.pathname)) {
        setShowOnBoardProgress(true);
        let step = 0;
        if (fiduciary.onBoardingStep === "PAYMENT") {
          step = 25;
        } else if (fiduciary.onBoardingStep === "COMPANY") {
          step = 50;
        } else if (fiduciary.onBoardingStep === "COLLABORATORS") {
          step = 75;
        } else if (fiduciary.onBoardingStep === "COMPLETE") {
          setShowOnBoardProgress(false);
        }
        setOnBoardProgress(step);
      }
    }
  }, [fiduciary]);

  const rightIcons = {
    home: {
      activated: false,
      url: TTP_ONBOARDING_URL,
    },
    profile: {
      activated: false,
      url: "/profile",
    },
    ebox: {
      activated: false,
    },
    search: {
      activated: false,
    },
    notifs: {
      activated: false,
    },
    backoffice: {
      activated: false,
    },
    faq: {
      activated: false,
    },
    apps: {
      activated: false,
    },
  };

  const settings = [];
  if (auth.user && auth.user.isUaAdmin) {
    settings.push({
      label: _("manage_fiduciary"),
      url: "/manage-fiduciaries",
    });
    settings.push({
      label: _("manage_apps"),
      url: "/manage-apps",
    });
    // settings.push({
    //   label: _("manage_services"),
    //   url: "/manage-services",
    // });
    settings.push({
      label: _("manage_specialties"),
      url: "/manage-specialties",
    });
    settings.push({
      label: _("contact_messages"),
      url: "/contact-messages",
    });
    settings.push({
      label: _("manage_packs"),
      url: "/manage-packs",
    });
    settings.push({
      label: _("manage_membership"),
      url: "/manage-membership",
    });
    settings.push({
      label: _("themes_pages"),
      url: "/themes",
    });
  }
  if (auth.user && auth.user.type == "ADMIN") {
    settings.push({
      label: _("logged_as"),
      url: "/logged-as",
    });
  }

  const changeLng = (language) => {
    // document.location.href = "/" + language;
    dispatch(setLanguage(language));
    window.location.reload();
  };

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
  };
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const policy = [];
  const cookies = [];

  let privacyRubs = [];
  if (privacyRubrics.length > 0) {
    if (
      privacyRubrics.filter((el) => el.termsOfUse).length > 0 &&
      privacyRubrics
        .filter((el) => el.termsOfUse)[0]
        .versions.filter((ver) => ver.name === "UNITED_ASSOCIATES").length > 0
    ) {
      policy.push({
        label: _("terms_of_use"),
        url: "/privacy/terms_of_use",
      });
    }

    privacyRubrics.forEach((el) => {
      if (el.isCookie) {
        if (
          el.versions &&
          el.showInFooter &&
          el.versions[0].status === "PUBLISHED"
        ) {
          cookies.push({
            label:
              el.versions[0][titleAttr].length > 25
                ? el.versions[0][titleAttr].substring(0, 25) + "..."
                : el.versions[0][titleAttr],
            url: `/privacy/cookies?rub=${el.id}`,
          });
        } else if (!el.versions) {
          cookies.push({
            label: _("manage_cookies"),
            url: `/privacy/cookies`,
          });
        }
      }
      if (
        el.showInFooter &&
        el.versions[0].status === "PUBLISHED" &&
        !el.isCookie &&
        !el.termsOfUse
      )
        privacyRubs.push({
          label:
            el.versions[0][titleAttr].length > 25
              ? el.versions[0][titleAttr].substring(0, 25) + "..."
              : el.versions[0][titleAttr],
          url: `/privacy?rub=${el.id}`,
        });
    });
  }

  if (!auth.user) {
    return null;
  } else {
    return (
      <HeaderUA
        rightIcons={rightIcons}
        app={{
          appName: "United Associates",
          appLogoUrl:
            "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/logo/ua.png",
          appUrl: "/",
        }}
        auth={{
          navCommunity: auth.navCommunity,
          user: auth.user,
          token: auth.token,
        }}
        onSelectCommunity={(community) =>
          dispatch(setNavCommunity(community.organization))
        }
        settings={settings}
        lng={lng}
        languages={["fr", "nl", "en"]}
        env={APP_ENV}
        onLanguageChange={(langue) => changeLng(langue)}
        onLogoutClick={(e) => handleLogout(e)}
        portalSwitch={portalSwitch}
        currentPortal={currentPortal}
        RouterLink={Link}
        firstList={privacyRubs.length > 0 ? privacyRubs : null}
        secondList={cookies.length > 0 ? cookies : null}
        thirdList={policy.length > 0 ? policy : null}
        navigateTo={(url) => {
          if (
            url.includes("privacy") ||
            url.includes("/client") ||
            url.includes("/collaborator") ||
            url.includes("/settings")
          ) {
            navigate(url);
          } else {
            window.location.href = "http://" + url;
          }
        }}
        showOnBoardProgress={showOnBoardProgress}
        onBoardProgress={onBoardProgress}
        showProfileLink={
          fiduciary?.onBoardingStep === "COMPLETE" ? true : false
        }
        showPersonalData={
          fiduciary?.onBoardingStep === "COMPLETE" ? true : false
        }
        personalData={{
          numeroAgreation: user.numeroAgreation,
          agreationType: user.agreationType,
          agreationTitle: user.agreationTitle,
          agreationParam: user.agreationParam,
        }}
      />
    );
  }
};

export default Menu;
