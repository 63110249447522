const faqEn = {
  continue: "Continue",
  step_company_details: "Company details",
  step_collaborator: "Collaborators",
  step_clients: "Clients",
  step_contacts: "Other contacts",
  step_students: "Students",
  add_collaborator_title: "Add collaborators",
  add_student_title: "Add students",
  express: "Express",
  manual: "Manual",
  import_xls_file: "Import (.xls)",
  email_address: "Email address",
  email: "Email",
  firstname: "First name",
  lastname: "Last name",
  add: "Add",
  cancel: "Cancel",
  english: "English",
  french: "French",
  dutch: "Dutch",
  phone_number: "Phone",
  collaborator_role: "Collaborator role",
  language: "Language",
  LEGAL_REPRESENTATIVE: "Legal representative",
  MANAGER: "Manager",
  OFFICIAL: "Collaborator",
  EXTERNAL: "External",
  ADMINISTRATOR: "Administrator",
  validate_email: "Please provide a valid email address",
  required_field: "This field is required",
  required_2_characters: "Please provide at least 2 characters",
  collaborators_list: "Collaborators list",
  import_collaborators: "Import Employees",
  import_contacts: "Import contacts",
  edit: "Edit",
  delete: "Delete",
  delete_confirm: "Delete confirmation",
  text_delete_collaborator:
    "Are you sure you want to delete this collaborator?",
  no: "No",
  yes: "Yes",
  add_contact_title: "Add other contacts",
  contacts_list: "Contacts list",
  finish: "Finish",
  gender: "Gender",
  text_delete_service: "Are you sure you want to delete this service?",
  service_title: "Service title",
  service_description: "Service description",
  select_icon: "Select icon",
  is_private: "Is Private",
  add_service: "Add Service",
  validate_service_title: "Titles are required",
  validate_service_title_length: "Titles should contain 3 characters or more",
  validate_service_icon: "Icon is required",
  something_went_wrong: "Something went wrong",
  update_service: "Update Service",
  opening_time: "Schedule",
  office_closed: "Closed",
  all_training: "All training",
  all_authors: "All authors",
  no_article_found: "No article found",
  welcome_to: "Welcome to",
  home_description:
    "Create your digital base and join the new technological network of economic and legal professions.",
  subscribe: "Subscribe to the United Associates network",
  already_subscribed: "I am already subscribed",
  home_footer: "The technological base is developed by Tamtam international.",
  validate: "Valider",
  male: "Homme",
  female: "Female",
  mobile_phone_number: "Numéro Téléphone Mobile",
  email_validated_successfully: "Adresse email validée avec succès",
  account_exist_login: "Ce compte existe. Veuillez vous connecter",
  account_exist_pwd: "Account exist. Please set a password",
  password_weak: "Le mot de passe est faible!",
  password_not_match: "Veuillez entrez à nouveau la même valeur",
  password: "Mot de passe",
  confirm_password: "Confirmez le mot de passe",
  numeroAgreation: "Numéro d'agréation (professions économiques)",
  check_your_email:
    "Veuillez vérifier votre boite email pour valider votre adresse",
  already_sent_email:
    "Nous avons déjà envoyer un email de confirmation dans votre boite.",
  resend_confirmation_email: "Réenvoyer un email de confirmation",
  create_account: "Créer un compte",
  signup: "S’inscrire",
  signin: "Se connecter",
  forgot_password: "Oublié?",
  must_accept_conditions: "Vous devez accepter nos conditions d'utilisation",
  itaa: "Bureau fiduciaire ITAA",
  ire_audit: "Cabinet d’audit IRE",
  law_office: "Cabinet d’avocats",
  notarial_office: "Cabinet d’étude Notariale",
  other: "Autres...",
  validate_phone: "Le numéro de téléphone est invalide",
  title_office_step_1: "You are?",
  subTitle_office_step_1:
    "As professionals, you act as a natural person or in a company and have an authorization for this purpose",
  title_office_step_2: "Company Office Data",
  subTitle_office_step_2:
    "Practice contact information will be used for billing. Size defines your subscription.",
  title_office_step_3: "Join United Associates",
  subTitle_office_step_3:
    "Unted Associates, the premier peer-to-peer technology network.",
  checkbox_person_title: "office",
  checkbox_person_subTitle: "natural person",
  checkbox_person_description:
    "Exercising as a natural person assumes that you are not using a legal person to exercise",
  checkbox_office_title: "office",
  checkbox_office_subTitle: "company",
  checkbox_office_description:
    "Exercising in a company supposes that you use a legal person to exercise.",
  checkbox_fiduciary_title: "Office",
  checkbox_fiduciary_subTitle: "ITAA Fiduciary",
  checkbox_itaa_title: "Office",
  checkbox_itaa_subTitle: "ITAA Fiduciary",
  checkbox_ire_audit_title: "Firm",
  checkbox_ire_audit_subTitle: "IRE Audit",
  checkbox_law_office_title: "Law Firm",
  checkbox_law_office_subTitle: "Lawyers",
  checkbox_notarial_office_title: "Firm",
  checkbox_notarial_office_subTitle: "Notarial Study",
  checkbox_other_subTitle: "Other...",
  app_blog_desc:
    "Use the Blog application for free access to economic, legal, accounting and tax news. Use this content for your organization and complete it with your own content or curated content.",
  app_blog_details_1_title: "All industry news",
  app_blog_details_1_subTitle:
    "Included in your subscription the FFF Blog and its channels",
  app_blog_details_2_title: "Transform Blog into a clean communication tool",
  app_blog_details_2_subTitle: "2 of 3 tools included in your subscription",
  app_blog_tools_1_title: "Write your articles",
  app_blog_tools_1_desc:
    "Add a personal layer to your news. This option allows you to create your own blog, in addition to that of the FFF and its channels.",
  app_blog_tools_2_title: "Insert external articles",
  app_blog_tools_2_desc:
    "Referenced external articles that speak to you: this is curating, available through this option.",
  app_blog_tools_3_title: "Create Newsletters",
  app_blog_tools_3_desc:
    "Assemble a selection of articles for your mailing list. Communicating by newsletter has never been easier.",
  app_faq_desc:
    "Access a library of the most popular questions and answers. Use these contents for your organization and create a support center for your customers.",
  app_faq_details_1_title: "All Industry Q&A",
  app_faq_details_1_subTitle:
    "Included in your subscription the FAQ FFF and its channels",
  app_faq_details_2_title: "Transform FAQ into a clean communication tool",
  app_faq_details_2_subTitle: "2 of 2 tools included in your subscription",
  app_faq_tools_1_title: "Create your FAQs",
  app_faq_tools_1_desc:
    "Add a personal layer to your FAQ. This option allows you to create your own FAQs, in addition to those of the FFF and its channels.",
  app_faq_tools_2_title: "Create your help desk",
  app_faq_tools_2_desc:
    "Incorporate the knowledge listed in the FAQs to provide your customers with a help desk in a 'follow the guide' format",
  app_webinar_desc:
    "Access an online training library for the entire firm based on the oFFFcourse library and that of its channels. Depending on your subscription, you have one or more premium accesses (with certification) and unlimited sharing. Then complete the available formations of your own formations.",
  app_webinar_details_1_title:
    "All training in the sector: an educational lever",
  app_webinar_details_2_title: "Share my Premium",
  app_webinar_details_2_subTitle:
    "This option allows you to share without certification with 4 members of your firm a premium subscription, 8 members 2 premium subscription, etc...",
  app_webinar_tools_1_title: "Share my premium",
  app_webinar_tools_1_desc:
    "This option allows you to share your premium subscription with all members of your firm.",
  app_webinar_tools_2_title: "Offer premium to all",
  app_webinar_tools_2_desc:
    "This option allows you to share the library with all members of the firm while managing their programs and certifications.",
  app_webinar_tools_3_title: "Create my own webinars",
  app_webinar_tools_3_desc:
    "This option allows you to create your own webinars and share them with your collaborators.",
  app_webinar_tools_4_title: "Share webinars with my clients",
  app_webinar_tools_4_desc:
    "This option allows you to share webinars with your customers through the customer portal.",
  app_webtools_desc:
    "Access a library of the most popular webtools. Use this content for your organization and create a tool center for your customers.",
  app_webtools_details_1_title: "All industry webtools",
  app_webtools_details_1_subTitle:
    "Included in your subscription the FFF Webtools",
  app_webtools_details_2_title:
    "Turn webtools into a resource library for your customers",
  app_webtools_details_2_subTitle:
    "2 out of 3 tools included in your subscription",
  app_webtools_tools_1_title: "Create your webtools",
  app_webtools_tools_1_desc:
    "Add a personal layer to your webtools. This option allows you to create your own webtools, in addition to those of the FFF and its channels.",
  app_webtools_tools_2_title: "Create your tool center",
  app_webtools_tools_2_desc:
    "Incorporate the tools listed in webtools to provide your customers with a help desk in a 'follow the guide' format",
  app_event_desc:
    "Access a face-to-face training library for the entire firm based on the library of oFFFcourse vents and that of its channels. Then complete the catalog of these vents by adding your own vents",
  app_event_details_1_title: "All Industry Events",
  app_event_details_1_subTitle:
    "Only the free courses of oFFFcourse and its channels are included in your subscription",
  app_event_details_2_title:
    "Organize your own training for your customers and employees",
  app_event_details_2_subTitle: "2 of 3 tools included in your subscription",
  app_event_tools_1_title: "Create my own free events",
  app_event_tools_1_desc:
    "This option allows you to create your own free events and manage invitations and its organization.",
  app_event_tools_2_title: "Create my own paid events",
  app_event_tools_2_desc:
    "This option allows you to create your own paid events and manage invitations and its organization.",
  app_accounting_desc:
    "Organize the presentation of your annual work to your clients using the new presentation tool in slides show format (Dashboard). Then complete the personalized format presentation.",
  app_accounting_details_1_title:
    "All your clients benefit from professional reporting",
  app_accounting_details_2_title:
    "Transform your work into an educational and communicational operation",
  app_accounting_details_2_subTitle:
    "2 out of 3 tools included in your subscription",
  app_accounting_tools_1_title:
    "Extend your presentation through the use of a view and ratio battery",
  app_accounting_tools_1_desc:
    "This option allows you to add to your slides show a view of the annual accounts over several years, with various analyzes and comparisons.",
  app_accounting_tools_2_title: "Share the presentation on the customer portal",
  app_accounting_tools_2_desc:
    "This option allows you to share your slide show, analyzes and comparisons with your client on his personal portal.",
  app_accounting_tools_3_title: "Generate your own presentation standards",
  app_accounting_tools_3_desc:
    "This option allows you to create and personalize your presentations according to your needs and those of your customers.",
  version_beta: "Beta Version",
  offered_by_ua: "Offered by UA",
  until: "Until",
  business_num: "Business Number",
  approval_num: "Approval number",
  abbreviation: "Abbreviation",
  url_link: "URL link",
  address: "Address",
  activity_area: "Activity area",
  my_portals: "My Portals",
  installed_app: "AppStore",
  future_app: "Upcoming app",
  customer_records: "Customer Records",
  other_contacts: "Contacts",
  other_contact: "Other contacts",
  more_than: "more than",
  of: "of",
  close: "close",
  step_payment: "Payment",
  waiting_for_payment: "The order is awaiting payment",
  waiting_for_payment_subtitle:
    "To make your membership faster, proceed directly to your payment by favoring online payments..",
  successful_payment: "your payment has been made successfully",
  command_details: "Details of the order",
  facture_details: "Invoice details",
  membership_to_ua_for: "Membership in the United Associates network for",
  membership_ends_in: "This membership ends on",
  description: "Description",
  price: "Price",
  quantity: "Quantity",
  tva_rate: "VAT rate",
  tvac_amount: "VAT amount",
  membership_cabinet_of: "Membership for a firm of",
  to: "to",
  collaborators: "Collaborators",
  clients: "Clients",
  download_command: "Download order",
  download_invoice: "Download invoice",
  pay_here: "Pay here",
  choose_payment_method: "Choose your payment method",
  code_will_expire: "Payconiq QR code will be expired",
  in: "in",
  online_payment: "Online payment",
  selected: "Selected",
  choose: "Choose",
  bank_payment: "Bank transfer",
  use_this_card: "Pay with this card",
  new_card: "New credit card",
  card_number: "Card number",
  expiration_date: "Expiration date",
  remember_card: "Register my card",
  payment_denied: "Refused payment",
  payment_denied_p:
    "We regret that we cannot respond favorably to your request for payment.",
  retry_payment: "retry payment",
  summary: "Summary",
  subscription_choice: "subscription choice",
  joining_ua_for: "Membership of the United associates network for",
  number_employees: "Number of employees",
  between: "Between",
  and: "and",
  company_data: "Company data",
  payment: "payment",
  payment_date: "Payment date",
  payment_mode: "Payment method",
  amount: "Amount",
  status: "Statut",
  payment_made: "Payment made",
  subscribed_to_ua: "You are a United Associates subscriber",
  back: "back",
  my_profile: "My profile",
  add_new_email: "Add an email address",
  edit_license_number: "Change license number",
  set_as_principal: "Set this address as primary",
  edit_license_number_p:
    "The approval number is useful to allow us to give you certain access. This number is also essential in the context of approved training for the issuance of certificates.",
  ITAA_trust_office: "ITAA Trust Office",
  IRE_audit_firm: "IRE audit firm",
  law_office: "Law office",
  notarial_office: "Notarial office",
  other: "Other",
  manage_information: "Manage your personal information",
  photo: "Photo",
  photo_p: "Personalize your account by adding a photo",
  first_last_name: "Last name / First Name",
  principale: "Primary",
  non_principale: "Non primary",
  principale_email_p:
    "Your primary address is the address the system uses to contact you and communicate information to you. The use of several email addresses allows you to log in, regardless of the email address used for the same profile (account).",
  password: "Password",
  medium_password: "The password is Medium!",
  license_number: "Approval number (economic professions)",
  phone_number_1: "Phone number",
  office: "Office",
  phone_number_1_p:
    "Your primary phone number should ideally be a mobile number. It will improve security through two-factor identification.",
  add_new_phone_number: "Add a phone number",
  birth_date: "Date of Birth",
  gender_1: "Gender",
  reject: "Reject",
  put_on_pending: "Put on hold",
  ua_status_successfully: "Status changed successfully",
  ua_validate_confirm: "Confirm Change",
  text_ua_validate_confirm: "Do you want to validate the fiduciary?",
  text_ua_put_on_pending_confirm: "Do you want to put the fiduciary on hold?",
  text_ua_reject_confirm: "Do you want to reject fiduciary?",
  save: "Save",
  empty_list: "Empty list",
  all: "All",
  validated: "Validated",
  pending: "Pending",
  rejected: "Rejected",
  accepted: "Accepted",
  created: "Created",
  reminder: "Reminder",
  change_password: "Changer le mot de passe",
  reminder_email_sent_successfully:
    "The reminder email has been sent successfully",
  reset_password: "Reset password",
  user_added: "user(s) added",
  user_added_with_error: "user(s) added. Check remaining addresses",
  import_clients: "Import clients",
  CHIEF_EDITOR: "Chief Editor",
  AUTHOR: "Author",
  AUTHOR_MANDATED: "Mandated Author",
  EDITOR: "Editor",
  REDACTOR_MANDATED: "Mandated Editor",
  modal_avatar_title: "Customize Image",
  save: "Save",
  change_image: "Change Image",
  zoom: "Zoom",
  rotation: "Rotation",
  edit_name: "Edit First name and Last name",
  change_password: "Change password",
  old_password: "Old password",
  set_as_principal_phone: "Set this number as primary",
  edit_birthday: "Edit Birthday",
  birthday: "Birthday",
  saving_changes: "Saving changes ...",
  edit_gender: "Edit gender",
  edit_language: "Edit Language",
  uen_label_number: "Enter the number",
  oldPassword_incorrect: "Old password incorrect",
  email_not_unique: "Email already taken",
  add_media: "Add new media",
  in_progress: "In progress",
  send: "Send",
  password_changed_succesfully: "Password changed successfully",
  reset_pwd_check_your_email:
    "A message has been sent to your e-mail box. Please consult it and follow the instructions.",
  successfully_saved: "Successfully saved",
  title_exist_fiduciary_confirm: "Confirmation",
  text_exist_fiduciary:
    "This trustee exists in Tamtam's database. Are you sure you want to continue?",
  search: "Search ...",
  save_confirm: "Save confirmation",
  text_save_group: "Do you want to add the selected contacts to this group?",
  text_delete_group: "Do you want to remove the contact from this group?",
  group_filter: "Filter groups",
  groups: "Groups",
  search_group: "Search for group",
  add_from_biblio: "Add from library",
  biblio_services: "Services Library",
  confirm: "Confirm",
  empty_biblio: "The library is empty",
  category: "Category",
  author_type: "Type of authors",
  accountant: "Accountant",
  tax_advice: "Tax Advice",
  lawyer: "Lawyer",
  notary: "Notary",
  consultant: "Consultant",
  professor: "Professor",
  journalist: "Journalist",
  others: "Other",
  article_feed: "Article Feed",
  both: "All (both)",
  my_articles: "My firm's publications",
  fff_articles: "FFF News",
  relevance: "Relevance",
  city: "Ville",
  zipCode: "Postal code",
  company_logo: "Company Logo",
  help_center_app: "help center",
  dashboard_app: "Dashboard",
  powerteam_app: "Powerteam",
  watch_app: "Watch",
  carousel: "Carousel",
  terms_of_use: "Terms of use",
  manage_cookies: "Manage Cookies",
  invalid_value: "Invalid value",
  add_confirm: "Add Confirmation",
  text_add_exist_collaborator:
    "This collaborator belongs to another organization. The fields will be disabled until the invitation is accepted. Are you sure you want to add it?",
  text_popover_add_exist_collaborator:
    "This collaborator belongs to another organization. Are you sure you want to add him? If not, you can delete this line",
  existing_medias: "Media library",
  split: "Split",
  merge: "Merge",
  fill_with_default_values: "Fill with default value",
  validate_data: "Please verify data",
  select_header: "Select header",
  map_fields: "Map fields",
  verify_finalize: "Verify & finalize",
  services_biblio: "Services Library",
  manage_public_services: "Manage public services here",
  add_new_service: "Add new service",
  text_popover_add_exist_client:
    "This customer belongs to another organization. Are you sure you want to add it? If not, you can remove this line",
  country_code: "Country code",
  text_add_exist_client:
    "This client belongs to another organization. Are you sure you want to add it?",
  webtools_app: "Webtool",
  our_fields: "Our fields",
  columns_in_file: "Columns in your file",
  preview: "Preview",
  map_columns: "Map columns",
  reduce: "Reduce",
  show_tools: "Show related Tools",
  SKILLS: "Skills",
  TECHNOLOGY: "Technology",
  CLIENTELE: "Clientele",
  AREA: "Area",
  BOOKKEEPING: "Bookkeeping",
  ADJUSTMENT_OF_THE_ANNUAL_ACCOUNTS: "Adjustment of the annual accounts",
  PERIODIC_SITUATION: "Periodic situation",
  FINANCIAL_PLAN: "Financial plan",
  BUSINESS_VALUATION: "Business valuation",
  UBO: "UBO",
  PAYROLL_MANAGEMENT_EMPLOYEE: "Payroll management (employee)",
  PAYROLL_MANAGEMENT_ADMINISTRATOR: "Payroll Management (Administrator)",
  MANAGEMENT_ALTERNATIVE_REMUNERATION: "Alternative remuneration management",
  PLAN_CAFETERIA: "Cafeteria plan",
  SPLIT_SALARY: "Split salary",
  IMPATRIATES: "Impatriates",
  BUSINESS_CREATION: "Business Creation",
  CORPORATE_REORGANIZATION: "Corporate reorganization",
  RECEIVERSHIP_PROCEEDINGS: "Recovery proceedings",
  BUSINESS_LIQUIDATION: "Business Liquidation",
  TRANSFER_BUSINESS: "Transfer of business",
  FAMILY_OFFICE: "Family office",

  TAX_RETURNS_IPP: "Tax returns to the IPP",
  TAX_RETURNS_ISOC: "Tax returns to ISOC",
  TAX_DECLARATIONS_INR: "INR tax declarations",
  TAX_DECLARATIONS_IPM: "Tax declarations to IPM",
  VAT_RETURN: "VAT return",
  TAX_REPRESENTATION: "Tax representation",
  ADMINISTRATIVE_LITIGATION: "Administrative litigation",
  LEGAL_LITIGATION: "Legal litigation",
  RULING: "Ruling (advance rulings)",
  WEALTH_PLANNING: "Wealth planning",
  GDPR_TIP: "GDPR Tip",
  STRATEGIC_CONSULTING: "Strategic Consulting",
  DIGITAL_STRATEGY: "Digital strategy",
  LBO: "LBO",
  M_A: "M&A",
  DRAFTING_STATUTES: "Drafting of statutes",
  DRAFTING_AGREEMENTS: "Drafting agreements",
  AUDIT_ACCOUNTS: "Account Audit",
  ENERGY_AUDIT: "Energy audit",
  CSR: "CSR",
  HORUS: "Horus",
  XPERT_M: "Xpert-M",
  WINBOOKS: "Winbooks",
  EXACT_ONLINE: "Exact online",
  POPSY_ALLEGRO: "Popsy - Allegro",
  YUKI: "Yuki",
  BOB_SOFTWARE: "Bob software",
  OCTOPUS: "Octopus",
  ODOO: "odoo",

  BILLTOBOX: "BillToBox",
  BILLIT: "BillIt",
  FALCON: "Falcon",
  WINBOOKS_CONNECT: "Winbooks connect",
  LIBERAL_PROFESIONAL: "Liberal Professional",
  INDIVIDUALS: "Natural persons",
  SMALL_COMPANIES: "Small companies",
  MEDIUM_SIZED_COMPANIES: "Medium Companies",
  LARGE_COMPANIES: "Large companies",
  SMALL_NON_PROFIT_ORGANIZATIONS: "Small NPOs",
  MEDIUM_AND_LARGE_NON_PROFIT_ORGANIZATIONS: "Medium and large ASBL",
  NON_RESIDENTS: "Non residents",
  FOREIGN_COMPANIES: "Foreign Companies",
  DOCTOR: "Doctor",
  PROFESSIONS: "Liberal professions",
  PHYSIOTHERAPIST: "Physio",
  PHARMACIST: "Pharmacist",
  DENTISTS: "Dentists",
  CULTURE: "Culture",
  ARTISTS: "Artists",
  PERFORMING_ARTS: "Performing Arts",
  YOUTH_ASSISTANCE: "Youth assistance",
  National: "National",
  Regional: "Regional",
  Local: "Local",
  ACCOUNTING_AUDITING: "Accounting & Audit",
  TAX_ADVICE: "Tax Advice",
  SOCIAL_COUNCIL: "Social Council",
  LEGAL_ADVICE: "Legal Advice",
  ACCOMPANIMENT: "Accompaniment",
  CERTIFICATION: "Certification",
  CONSULTING: "Consulting",
  SUPPORTED_ACCOUNTING_SOFTWARE: "Supported accounting software",
  DIGITAL_PLATFORMS: "Digital platforms",
  TYPE_OF_PROFESSIONAL: "Type of professional",
  SECTOR: "Sector",
  specialty: "specialty",
  manage_fiduciary: "Manage fiduciary",
  manage_specialties: "Manage specialties",
  manage_apps: "Manage apps",
  contact_messages: "Contact messages",
  fiduciary_contact_messages: "Fiduciary contact messages",
  nothing_found: "Nothing Found",
  sent_at: "Sent at",
  name: "Name",
  select_message_please: "Select a Message Please",
  manage_fiduciary_specialties: "Manage fiduciary specialties",
  text_delete_item: "Are you sure you want to delete this item ?",
  add_specialty: "Add specialty",
  skills: "Skills",
  subject: "Subject",
  message: "Message",
  send_message: "Send message",
  add_specialty_bloc: "Add specialty bloc",
  title_required: "Title required",
  icon_required: "Icon required",
  create_specialty_type: "Create Specialty Type",
  create_specialty: "Create a specialty",
  create_specialty_bloc: "Create a specialty bloc",
  name_fr: "Name Fr",
  name_en: "Name En",
  name_nl: "Name Nl",
  has_choices: "Unique choice",
  isActivated: "Activated",
  multiselect: "Multiple selection",
  number_favorites_allowed: "Number of favorites allowed",
  search_placeholder: "Search...",
  reply: "Reply",
  at: "at",
  successfully_sent: "Successfully sent",
  subject_to_vat: "Subject to VAT",
  default_domain: "Default domain",
  custom_subdomain: "Custom subdomain",
  create_group: "Create group",
  Day: "Day",
  Morning: "Morning",
  Afternoon: "Afternoon",
  MON: "MON",
  TUE: "TUE",
  WED: "WED",
  TUR: "GAME",
  FRI: "FRI",
  SAT: "SAT",
  SUN: "SUN",
  Apply_for_all_days: "Apply for all days",
  "No work": "No work",
  select_category: "Choose a category",
  select_type: "Select Type",
  no_options: "No options",
  Specialties: "Specialties",
  specialties_subtitle:
    "Specify your skills, technologies, type of clientele and geographical area. Avoid checking everything...",
  title_fr: "French Title",
  title_en: "English Title",
  title_nl: "Dutch title",
  description_fr: "French Description",
  description_en: "English Description",
  description_nl: "Dutch Description",
  article_feed_tooltip: "Select the origin of articles to display",
  scope_tooltip:
    "Each article has a 'scope' information. Filter the articles to display according to the scope",
  relevance_tooltip: "Only display articles with a relevance of at least...",
  manage_account: "Manage my subscription",
  public_domain: "Public domain",
  public_domain_rules:
    "Subdoamin has to be unique and not contain spaces or any special characters",
  suggestion: "Suggestion:",
  invalid_subdomain:
    "Invalid subdoamin, subdoamin should not contain spaces or any special characters.Please check the suggested subdomain down below",
  subdomain_already_exists: "Subdomain already exists",
  newsletter_preferences_title: "Select your Newsletter preferences",
  plans_newsletter_title: "L'APP' NEWSLETTERS - (In)Train your customersr",
  articles_option_title: "Article Feed",
  articles_option_1: "FFF News",
  articles_option_2: "My firm publications",
  articles_option_3: "Frequency of sending",
  customization_option_title: "Customization",
  customization_option_1: "My firm logo",
  customization_option_2: "Template theme",
  basic: "Basic",
  plans_newsletter_1_max_articles_fff: "2 articles",
  plans_newsletter_1_max_my_articles: "",
  light_mode: "Light Mode",
  edito: "Edito",
  plans_newsletter_2_max_articles_fff: "Up to 6 articles",
  plans_newsletter_2_max_my_articles: "Maximum 2 articles",
  personalized: "Personalised",
  plans_newsletter_3_max_articles_fff: "Up to 8 articles",
  plans_newsletter_3_max_my_articles: "Up to 8 articles",
  light_dark_mode: "Light or dark mode",
  month: "Month",
  predefined: "predefined",
  by_choice: "of your choice",
  by_week: "Weekly",
  by_two_weeks: "Bimonthly",
  by_month: "Monthly",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  define_sender: "Choose the sender",
  program_nl_title: "Scheduling your mailing (Plan ",
  manage_sending_emails:
    "This is where you choose the sender you are going to use to send your newsletter",
  first_two_weeks: "The first two weeks",
  second_two_weeks: "The second two weeks",
  first_week: "Week 1",
  second_week: "Week 2",
  third_week: "Week 3",
  fourth_week: "Week 4",
  manage_newsletter_campaign: "Manage the newsletter campaign",
  plans_newsletter_desc:
    "Via a simple, effective application, develop an informed relationship with your customers thanks to an automated, customizable newsletter tool. In just a few clicks, create high-quality newsletters and keep your most discerning customers up to date. With quick and easy set-up, an efficient validation system, and regular automatic delivery, your company will be pro-actively highlighted to your customers. Leverage the best in tax information, showcase your knowledge and share it with your customers",
  frequency_desc:
    "Weekly, fortnightly or monthly: select the frequency at which your newsletters are automatically sent.",
  fff_news_desc:
    "Depending on the option you choose, you can send up to 8 relevant articles, automatically or personalized, to your target audience. Each newsletter must, of course, be approved by the editor-in-chief (the fiduciary's newsletter manager)",
  my_pub_desc:
    "Create and share your own newsletters with employees and customers",
  pub_logo_desc:
    "Display your logo on each of your newsletters to highlight your expertise to your customers and collaborators.",
  theme_desc:
    "Personalize the colors of your newsletters and make the reader experience as close to your company as possible?",
  unique_mode: "Unique mode",
  customized_mode: "Customizable mode",
  week: "Week",
  week_desc_1: "Weeks 1 and 3",
  week_desc_2: "Weeks 2 and 4",
  newsletter_app_txt:
    "Keep your customers directly informed about the most relevant tax news with a library of high-value-added articles. With a simple click, generate a newsletter in the image of your fiduciary, fed by the best experts in the sector.",
  app_newsletter_details_1_title: "2 formulas available",
  app_newsletter_details_1_detail1:
    "Basic: A newsletter of 8 automatically-composed articles that you decide to send out at your own pace with a single click",
  app_newsletter_details_1_detail2:
    "Editorial: Create your own editorial and select the articles most relevant to your clientele",
  app_newsletter_details_1_detail3:
    "Full customization: Benefit from a newsletter management tool that can be fully customized and distributed at your own pace.",
  client_portal: "Client Portal",
  configuration: "Configuration",
  portals: "portals",
  public_portal: "Public portal",
  public_portal_txt:
    "A window on the world outside in a single click. You can publish your presence on the Internet without effort. There is a mini configuration, your fiduciary exists in a look & feel « United Associates » and the clients you know look for information about the base from your agency: localization, current events, equipment, heures d'ouverture... L'access est public, aucune authentication n'est necessaire.",
  collaborator_portal: "Portail Collaborator",
  collaborator_portal_txt:
    "This portal is always activated. It allows you to work in an environment where each of your employees can access and share the various UA resources. Access is possible for all employees who are logged in.",
  portal_collaborator_title: "Welcome to the Collaborator Portal",
  portal_collaborator_txt:
    "Your employee portal is not active. Ask your manager to activate the collaboration portal",
  program_nl_desc: "Define the dispatch criteria for your pack here",
  choose_date: "Sending date of your newsletter",
  choose_date_desc: "Set the date your newsletter will be sent here",
  performance_of: "Performance of",
  hour_abr: "h",
  hours: "hours",
  minutes: "minutes",
  over: "over",
  no_data_found: "No data found",
  lite: "Lite",
  recipient: "recipient",
  senders: "sender",
  wrong_file_format: "Wrong file format (should be an image)",
  file_upload_error: "File upload error",
  description_without_title: "You added a description without a title",
  add_at_least_one_title: "Title required",
  add_image: "Image required",
  edit_service: "Edit service",
  create_service: "Create service",
  image_import_text: "Drag an image here or Upload a file",
  classic_design: "Classic design",
  customizable_design: "Customizable design",
  testimonials: "Testimonials",
  add_testimonial: "Add testimonial",
  add_faq: "Add faq",
  answer_without_question: "You added an answer without a question",
  question_without_answer: "You added a question without an answer",
  question_required: "Question required",
  edit_faq: "Edit faq",
  create_faq: "Create faq",
  question_fr: "French Question",
  question_en: "English Question",
  question_nl: "Dutch Question",
  answer_fr: "French answer",
  answer_en: "English answer",
  answer_nl: "Dutch answer",
  testimonial_required: "Testimonial required",
  author_required: "Author required",
  edit_testimonial: "Edit testimonial",
  create_testimonial: "Create testimonial",
  testimonial_fr: "French testimonial",
  testimonial_en: "English testimonial",
  testimonial_nl: "Dutch testimonial",
  author: "Author",
  headline: "Headline",
  invitation_successfully: "Invitation accepted. Please log in!",
  invitation_pwd_successfully: "Invitation accepted. Please set a password!",
  invitation_reject: "Invitation rejected.",
  collaborator_exist: "This collaborator exists in your organization",
  university: "University",
  role: "Role",
  students: "Students",
  students_list: "List of students",
  recipients_desc:
    "Choose the number of employees and your customers who will benefit from your newsletters.",
  senders_desc: "Choose the number of employees who can send your newsletters.",
  users_imported_with_errors:
    "Some recordings were saved successfully, others encountered an error. Try Again",
  import_students: "Import students",
  show_more: "Show more",
  invitation_confirm: "Confirmation",
  invitation_confirm_text:
    "Are you sure you want to send an invitation to purchase a premium?",
  invitation_sent: "Invitation sent successfully",
  share_all_confirm_text:
    "Are you sure you want to share the premium with all employees?",
  details_pack_lite:
    "Lite: Send one newsletter per month to your customers. Pack limited to 25 customers.",
  details_pack_perso:
    "Full personalized: Give your employees the chance to keep in close touch with their customers. Send a newsletter with 8 articles every week. Up to 10,000 mailings per week.",
  themes_pages: "Themes & Pages",
  theme_title: "List of themes and pages",
  theme_sub_title: "Add or configure themes and pages",
  theme_search_placeholder: "Search themes",
  page_search_placeholder: "Search Pages",
  pages: "pages",
  dropzone_text:
    "Drag and drop files here, or click to select the files to download.",
  invalid_page_title: "Invalid page title",
  invalid_private_groups: "You must specify groups for subscribers",
  errors: "Errors",
  subscribers_groups: "Subscriber groups",
  invalid_domain_title: "Invalid domain title",
  invalid_theme_title: "Invalid theme title",
  invalid_theme_domain: "Select theme domain",
  add_domain: "Add a domain",
  edit_domain: "Edit the domain",
  edit_theme: "Edit the theme",
  add_theme: "Add a theme",
  theme_isdefault: "Default theme",
  is_editable: "Is Editable",
  used: "Used",
  unused: "Unused",
  modal_delete_title: "Delete confirmation",
  modal_delete_text: "Are you sure you want to delete this item?",
  add_page: "Add a page",
  linked_pages: "Linked pages",
  domains: "domains",
  domain_title: "List of domains",
  domain_sub_title: "Add or configure domains",
  packs_list: "Packs list",
  manage_packs: "Manage packs",
  add_new_pack: "Add new pack",
  formula: "Formula",
  add_app: "Add an application",
  has_slider: "Has a slider?",
  slider_min: "Minimum value",
  slider_max: "Maximum value",
  slider_step: "Increment value",
  slider_label: "Slider title",
  slider_text: "Slider description",
  delegate: "Delegate",
  delegations: "Delegations",
  title_delegation: "Delegation management",
  title_delegation_type: "Delegation types",
  delegation_type: "Delegation type",
  for_count_clients: "For %count% clients",
  title: "Title",
  REQUIRED_FIELD: "This field is required.",
  TOO_SHORT: "This field must contain at least 2 characters.",
  TOO_LONG: "This field cannot contain more than 10 characters.",
  checking_contact_existence: "(Checking the existence of a contact...)",
  add_to_contacts: "Add to contacts",
  text_add_contact: "Do you want to add this person to the contact list ?",
  source: "Source",
  FFF_SITE: "Forum for the future Website",
  SITE_ART_CONSULT: "Art Consultant Website",
  SITE_COMPTA_MEDECIN: "Comptabilité Médecin Website",
  SITE_COMPTA_CONSULTANT: "Comptabilité Consultant Website",
  SITE_COMPTA_VETERINAIRE: "Comptabilité Vétérinaire Website",
  SITE_COMPTA_AVOCAT: "Comptabilité Avocat Website",
  SITE_DEG: "DEG & PARTNERS Website",
  SITE_COMPTA_DENTISTE: "Comptabilité Dentiste Website",
  order_member_design: "Order member design",
  about: "About",
  manage_contacts: "Manage contacts",
  contact_added_successfully: "Contact added successfully",
  contact_already_exist: "This contact already exists",
  standard: "Standard",
  articles: "articles",
  new_sender: "New sender",
  full_name: "Full name",
  add_to_my_list: "Add to my list",
  manage_membership: "List of memberships",
  memberships: "Memberships",
  upload_max_file_size: "File size exceeds the %maxFileSize% limit.",
  Mark_as_sender: "Mark as sender",
  request_sent: "Request sent",
  request_sent_successfully: "Request sent successfully",
  customization_request: "Customization request",
  customization_request_confirm_title: "Domain name customization request",
  customization_request_confirm_text:
    "Do you want to make a personalization request?",
  or: "Or",
  save_priority: "Save the new order",
};
export default faqEn;
