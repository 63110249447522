import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import { toast } from "react-toastify";

import _ from "../../i18n";
import { saveFiduciaryStatus, saveOECCBBCollab } from "../../api";

import Loader from "../common/Loader";
import Button from "../common/Button";
import FormSelect from "../common/FormSelect";
import styles from "./FiduciaryModal.module.scss";

const modeOptions = [
  { value: "BETA", label: "BETA TESTER" },
  { value: "CLASSIC", label: "CLASSIC" },
  { value: "UNIVERSITY", label: "UNIVERSITY" },
  { value: "OECCBB_MEMBER", label: "OECCBB" },
];

const FiduciaryAcceptModal = ({
  modalOpen,
  setModalOpen,
  fiduciary,
  refreshList,
}) => {
  const auth = useSelector((state) => state.auth);
  const [isSaving, setIsSaving] = useState(false);

  const [uaMode, setUaMode] = useState(modeOptions[0]);

  useEffect(() => {
    if (fiduciary && fiduciary.uaMode) {
      const mode = modeOptions.filter((i) => i.value === fiduciary.uaMode);
      if (mode.length > 0) {
        setUaMode(mode[0]);
      }
    }
  }, [fiduciary]);

  const handleSave = () => {
    let dataNote = fiduciary.note ? fiduciary.note : [];
    const date = new Date();
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hour = String(date.getUTCHours()).padStart(2, "0");
    const minute = String(date.getUTCMinutes()).padStart(2, "0");
    const second = String(date.getUTCSeconds()).padStart(2, "0");
    const strDate =
      year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
    dataNote.push({
      date: strDate,
      msg: "STATUS_VALIDATED",
      creator: {
        id: auth.user.id,
        firstName: auth.user.firstName,
        lastName: auth.user.lastName,
      },
    });

    setIsSaving(true);
    saveFiduciaryStatus(auth.token, {
      id: fiduciary.id,
      uaStatus: "VALIDATED",
      uaMode: uaMode.value,
      note: JSON.stringify(dataNote),
    })
      .then(async (resp) => {
        if (
          uaMode.value === "OECCBB_MEMBER" &&
          fiduciary.allLegalRepresentative?.length > 0
        ) {
          await saveOECCBBCollab(auth.token, {
            id: fiduciary.id,
            userId: fiduciary.allLegalRepresentative[0].id,
          });
        }
        toast.success(_("ua_status_successfully"));
        refreshList();
        setIsSaving(false);
        setModalOpen(false);
      })
      .catch((e) => {
        setIsSaving(false);
      });
  };

  return (
    <Modal
      width="50vw"
      height="50vh"
      closable={false}
      visible={modalOpen}
      footer={null}
      onCancel={(e) => {
        e.stopPropagation();
        setModalOpen(false);
      }}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div className={styles.modal_header}>{_("text_ua_validate_confirm")}</div>
      <div
        className={styles.modal_close}
        onClick={(e) => {
          e.stopPropagation();
          setModalOpen(false);
        }}
      >
        <i className="icon-ttp-close"></i>
      </div>

      <div className={styles.content}>
        <div className="cell small-12 large-6 grid-x grid-margin-x">
          <div className="cell small-12 large-6">
            <FormSelect
              name="country"
              value={uaMode}
              label="Choisissez le mode à attribuer"
              handleChange={(option) => setUaMode(option)}
              options={modeOptions}
            />
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <Button variant="default" onClick={() => setModalOpen(false)}>
          {_("cancel")}
        </Button>
        {isSaving ? (
          <Button
            variant="primary"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <Loader
              style={{
                height: "10px",
              }}
              color={"#fff"}
            />
          </Button>
        ) : (
          <Button onClick={handleSave}>{_("validate")}</Button>
        )}
      </div>
    </Modal>
  );
};

export default FiduciaryAcceptModal;
