import React, { useEffect, useState } from "react";
import styles from "./AppsList.module.scss";

import _ from "../../i18n";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const appIcons = {
  BLOG: "blog.svg",
  FAQ: "faq.svg",
  DASHBOARD: "dashboard.svg",
  POWERTEAM: "powerteam.svg",
  WEBTOOLS: "webtools.svg",
  OFFFCOURSE: "offfcourse.svg",
  NEWSLETTER: "sending.svg",
};

const appLabels = {
  BLOG: "Blog",
  FAQ: _("help_center_app"),
  DASHBOARD: _("dashboard_app"),
  POWERTEAM: _("powerteam_app"),
  WEBTOOLS: _("webtools_app"),
  OFFFCOURSE: _("watch_app"),
  NEWSLETTER: "E-News",
};

const AppsList = ({ apps, activeApp, setActiveApp }) => {
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [tools, setTools] = useState([]);
  const auth = useSelector((state) => state.auth);
  const { user } = auth;

  useEffect(() => {
    if (fiduciary) {
      const preferences = fiduciary[`newsletterPreferences`];
      if (preferences && preferences.apps) setTools(preferences.apps);
      else setTools([]);
    } else {
      setTools([]);
    }
  }, [fiduciary]);

  if (apps.length === 0 || apps.length === 1) {
    return null;
  }

  let isNewsletterCreate =
    tools.length > 0 &&
    tools.filter((t) => t.type === "BLOG_NL_CREATE").length > 0 &&
    user.blogRole[0]?.role === "CHIEF_EDITOR";
  return (
    <div className={styles.dockContainer}>
      <div className={styles.dock}>
        <ul>
          {apps.map(
            (app) =>
              (app.id != "NEWSLETTER" ||
                (app.id === "NEWSLETTER" && isNewsletterCreate)) && (
                <li
                  key={app.id}
                  onClick={() => {
                    setActiveApp(app.id);
                    window.history.replaceState(null, null, "?APP=" + app.id);
                  }}
                  className={`${styles.appsBox_item} ${
                    activeApp === app.id && styles.active
                  }`}
                >
                  <span>{appLabels[app.id] ? appLabels[app.id] : app.id}</span>
                  <a href={"javascript:void(0);"}>
                    {appIcons[app.id] && (
                      <img src={`img/apps/${appIcons[app.id]}`} alt="" />
                    )}
                  </a>
                </li>
              ),
          )}
        </ul>
      </div>
    </div>
  );
};

export default AppsList;
