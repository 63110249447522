import axios from "axios";

import { TTP_API_URL } from "../config";

export const getUaSettings = ({ token, title, sort, page, start, limit }) => {
  const filter = [];

  if (title) {
    filter.push({ property: "title", value: title, operator: "like" });
  }

  const fields = ["*"];

  let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(","),
    start: page ? (page - 1) * limit : start,
    limit,
  };

  if (sort) {
    params.sort = JSON.stringify([sort]);
  }

  const requestUrl = `${TTP_API_URL}/organization/ua-settings`;

  return axios.get(requestUrl, {
    params,
  });
};

export const saveUaSettings = ({
  token,
  title,
  allowedApps,
  allowedTabs,
  id,
}) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-settings`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("title", title);
  formData.append("allowedApps", allowedApps);
  formData.append("allowedTabs", allowedTabs);

  if (id) {
    formData.append("id", id);
  }

  return axios.post(requestUrl, formData);
};
