import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import classNames from "classnames";
import moment from "moment";

import CommandDetails from "./CommandDetails";
import PeriodChoice from "./PeriodChoice";
import styles from "./Payment.module.scss";

import _ from "../../../i18n";
import {
  addOrder,
  getOrder,
  getOrderDetails,
  getFiduciary,
  getRateBySize,
} from "../../../api";
import { TTP_PAYMENT_URL, API_DATE_FORMAT } from "../../../config";
import { setOnBoardingStep } from "../../../store";
import { formatUen } from "../../../utils";

import Button from "../../common/Button";

const Payment = ({ setCurrentStep }) => {
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const language = useSelector((state) => state.params.language);
  const [order, setOrder] = useState(null);
  const [orderPayment, setOrderPayment] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentValid, setIsPaymentValid] = useState(false);
  const [showIframe, setShowIframe] = useState(false);
  const [paymentStep, setPaymentStep] = useState(1);
  const [period, setPeriod] = useState("YEARLY"); // MONTHLY
  const [rate, setRate] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (token && fiduciary) {
      if (fiduciary.onBoardingStep !== "CREATED") {
        setPaymentStep(2);
        if (!orderPayment) {
          loadBillingOrder(false);
        }
      } else {
        setIsLoading(true);
        if (paymentStep === 1) {
          getRateBySize(token, fiduciary.id)
            .then((response) => {
              if (response.data.data) {
                setRate(response.data.data);
                if (response.data.data.isBeta) {
                  setPeriod("MONTHLY");
                }
                setIsLoading(false);
              }
            })
            .catch((e) => {
              setIsLoading(false);
              toast.error(_("rate_notfound"));
              navigate("/welcome");
            });
        } else {
          addOrder({
            token,
            organizationId: fiduciary.id,
            language,
            period,
          })
            .then((response) => {
              if (response.data.data) {
                setOrder(response.data.data);
                setShowIframe(true);
                setTimeout(() => setIsLoading(false), 800);
              }
            })
            .catch((e) => {
              setIsLoading(false);
              toast.error(_("error_add_order"));
              navigate("/welcome");
            });
        }
      }
    }
  }, [token, fiduciary, paymentStep]);

  useEffect(() => {
    window.addEventListener("message", function (e) {
      const eventId = e.data.event;
      if (eventId === "PAYMENT_OK") {
        setShowIframe(false);
        loadBillingOrder(true);
      } else if (eventId === "RETRY_PAYMENT") {
        location.reload();
      }
    });
  }, []);

  const loadBillingOrder = async (refreshFiduciary) => {
    if (isLoading) {
      return null;
    }
    setIsLoading(true);
    let orderId = null;
    if (!refreshFiduciary && fiduciary.uaOrder) {
      const { uaOrder } = fiduciary;
      let lastOrder = uaOrder[uaOrder.length - 1];
      orderId = lastOrder.orderId;
    } else {
      const response = await getFiduciary({
        token,
        fiduciaryId: fiduciary.id,
      });
      if (response?.data?.data[0]) {
        const uaOrder = response.data.data[0].uaOrder;
        let lastOrder = uaOrder[uaOrder.length - 1];
        orderId = lastOrder.orderId;
      }
    }
    if (orderId) {
      getOrder(token, orderId).then((response) => {
        if (response.data.data && response.data.data.length > 0) {
          setOrderPayment(response.data.data[0]);
          getOrderDetails(token, orderId).then((resp) => {
            setIsPaymentValid(true);
            setOrderDetails(resp.data.data);
            setIsLoading(false);
          });
        }
      });
    } else {
      setIsLoading(false);
    }
  };

  const handleNext = () => {
    if (fiduciary.onBoardingStep === "CREATED") {
      dispatch(setOnBoardingStep("PAYMENT"));
    }
    setCurrentStep(2);
  };

  if (isLoading) {
    return (
      <div className={styles.payment}>
        <div className="lmask"></div>
      </div>
    );
  }

  return paymentStep === 1 ? (
    <div className={styles.payment}>
      <PeriodChoice period={period} setPeriod={setPeriod} rate={rate} />
      <div className={styles.actionBar}>
        <span></span>
        <Button
          onClick={() => setPaymentStep(2)}
          iconRight="ttp-double-arrow-right"
        >
          {_("continue").toUpperCase()}
        </Button>
      </div>
    </div>
  ) : (
    <div className={styles.payment}>
      <div className={styles.title}>
        {isPaymentValid ? (
          orderPayment.freeCycle && !orderPayment.invoice ? (
            <h4 className={styles.successSub}>{_("subscribed_to_ua")}</h4>
          ) : (
            <h4>{_("successful_payment")}</h4>
          )
        ) : (
          <>
            <h4>{_("waiting_for_payment")}</h4>
            <span>{_("waiting_for_payment_subtitle")}</span>
          </>
        )}
      </div>

      {showIframe && (
        <iframe
          className={styles.iframe}
          src={`${TTP_PAYMENT_URL}/paymentMethode?orderID=${order.id}&token=${auth.token}&embedded=1`}
          title="UA"
        />
      )}

      {isPaymentValid &&
      orderPayment &&
      orderPayment.freeCycle &&
      !orderPayment.invoice ? (
        <div className={classNames(styles.freeCycle)}>
          <h4>{_("summary")} :</h4>
          <div className="grid-x grid-margin-x">
            <div className={classNames(styles.section, "cell medium-6")}>
              <div className={styles.sectionTitle}>
                <i className="icon-ttp-check-outline" />
                {_("subscription_choice")}
              </div>
              <h6>{orderPayment.comment}</h6>
              <p>
                <label className={styles.bold}>
                  {orderDetails && orderDetails[0].description}
                </label>
              </p>
            </div>
            <div className={classNames(styles.section, "cell medium-6")}>
              <div className={styles.sectionTitle}>
                <i className="icon-ttp-check-outline" />
                {_("company_data")}
              </div>
              <h6>{orderPayment.clientOrganization}</h6>
              <p>{formatUen("BE" + orderPayment.clientUen)}</p>
              <p>
                {orderPayment.clientStreet +
                  " - " +
                  orderPayment.clientPostalCode +
                  " " +
                  orderPayment.clientLocality}
              </p>
              <p>{_("subject_to_vat")}</p>
            </div>
          </div>
        </div>
      ) : (
        isPaymentValid &&
        orderPayment &&
        orderDetails && (
          <div className={classNames(styles.step4Content, "grid-x")}>
            <CommandDetails
              orderPayment={orderPayment}
              orderDetails={orderDetails}
            />
            <div className={classNames(styles.recap, "cell medium-4")}>
              <h4>{_("summary")} :</h4>
              <div className={styles.section}>
                <div className={styles.sectionTitle}>
                  <i className="icon-ttp-check-outline" />
                  {_("subscription_choice")}
                </div>
                <h6>{orderPayment.comment}</h6>
                <p>
                  <label className={styles.bold}>
                    {orderDetails[0].description}
                  </label>
                </p>
                <p>
                  {orderPayment.frequency === "YEARLY"
                    ? _("yearly_subscription")
                    : _("monthly_subscription")}
                  {fiduciary.subscriptionEnd &&
                    ", " +
                      _("valid_until") +
                      " " +
                      moment(fiduciary.subscriptionEnd, API_DATE_FORMAT)
                        .locale(language)
                        .format(`DD MMM YYYY`)}
                </p>
              </div>
              <div className={styles.section}>
                <div className={styles.sectionTitle}>
                  <i className="icon-ttp-check-outline" />
                  {_("company_data")}
                </div>
                <h6>{orderPayment.clientOrganization}</h6>
                <p>{formatUen("BE" + orderPayment.clientUen)}</p>
                <p>
                  {orderPayment.clientStreet +
                    " - " +
                    orderPayment.clientPostalCode +
                    " " +
                    orderPayment.clientLocality}
                </p>
                <p>{_("subject_to_vat")}</p>
              </div>
              <div className={styles.section}>
                <div className={styles.sectionTitle}>
                  <i className="icon-ttp-check-outline" />
                  {_("payment")}
                </div>
                <p>
                  {_("amount")} :{" "}
                  <label className={styles.bold}>
                    {orderPayment.includingVatAmount / 100} €
                  </label>
                </p>
                <p>
                  {_("status")} :{" "}
                  <label className={styles.bold}>{_("payment_made")}</label>
                </p>
              </div>
              <p className={styles.congrats}>
                <i className="icon-ttp-check" />
                {_("subscribed_to_ua")}
              </p>
            </div>
          </div>
        )
      )}

      <div className={styles.actionBar}>
        <Button
          variant="default"
          icon="ttp-double-arrow-left"
          onClick={() => setPaymentStep(1)}
        >
          {_("back").toUpperCase()}
        </Button>

        <Button
          onClick={() => handleNext()}
          iconRight="ttp-double-arrow-right"
          disabled={!isPaymentValid}
        >
          {_("continue").toUpperCase()}
        </Button>
      </div>
    </div>
  );
};

export default Payment;
