import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { Button } from "../";
import { StepOne, StepTwo } from "./steps";
import styles from "./CreateOffice.module.scss";
import _ from "../../i18n";
import { saveFolder, saveRole, getUserHasFiduciaryPlan } from "../../api";
import { fetchClientToken, setFiduciary } from "../../store";
import { AGREATION_INPUT_SIZE } from "../../config";
import { validateUen, formatUen } from "../../utils";
import Checkbox from "../common/Checkbox";

const CreateOffice = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initFields = {
    id: "",
    uen: "",
    name: "",
    type: "ITAA",
    status: "CREATED",
    uaStatus: "PENDING",
    address1: "",
    zipCode: "",
    city: "",
    approval: "",
    size: "",
    officeStatus: "OFFICE",
  };
  const [data, setData] = useState(initFields);
  const [errors, setErrors] = useState({
    uen: "",
    name: "",
    type: "",
    address1: "",
    zipCode: "",
    city: "",
    approval: "",
    size: "",
  });
  const [isOeccbbMember, setIsOeccbbMember] = useState(false);
  const [step, setStep] = useState(1);
  const [isAccepted, setIsAccepted] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loaderMembership, setLoaderMembership] = useState(false);
  const [isExistFiduciary, setIsExistFiduciary] = useState(false); // Fiduciry exist with same uen

  const isValidForm = () => {
    setErrors({
      ...errors,
      uen: !validateUen(data.uen) ? _("validate_uen") : "",
      name: data.name.length === 0 ? _("required_field") : "",
      address1: data.address1.length === 0 ? _("required_field") : "",
      zipCode: data.zipCode.length === 0 ? _("required_field") : "",
      city: data.city.length === 0 ? _("required_field") : "",
      size:
        data.size.length === 0 && data.type !== "UNIVERSITY"
          ? _("required_field")
          : "",
      type: !isValidType() ? _("required_field") : "",
    });

    return (
      data.uen !== "" &&
      data.name !== "" &&
      data.address1 !== "" &&
      data.zipCode !== "" &&
      data.city !== "" &&
      ((data.size !== "" && data.type !== "UNIVERSITY") ||
        data.type === "UNIVERSITY") &&
      isValidType()
    );
  };

  const isValidType = () => {
    return (
      !["ITAA", "IRE_AUDIT"].includes(data.type) ||
      (["ITAA", "IRE_AUDIT"].includes(data.type) &&
        AGREATION_INPUT_SIZE[data.type] === data.approval.length)
    );
  };

  const clickNext = () => {
    if (isOeccbbMember) {
      if (isValidForm()) {
        handleSubmit();
      }
    } else {
      if (step < 2) {
        if (isValidForm()) {
          setStep(step + 1);
        }
      } else {
        handleSubmit();
      }
    }
  };

  const clickPrev = () => {
    if (step > 1) setStep(step - 1);
  };

  const handleCheckboxClick = () => {
    setIsAccepted(!isAccepted);
  };

  const handleSubmit = async () => {
    setLoader(true);
    if (isExistFiduciary) {
      const roleResponse = await saveRole(auth.token, data.id);
    }

    saveFolder({
      token: auth.token,
      name: data.name,
      id: data.id,
      uen: data.uen,
      address1: data.address1,
      zipCode: data.zipCode,
      city: data.city,
      type: data.type,
      status: data.status,
      uaStatus: data.uaStatus,
      officeStatus: data.officeStatus,
      officeType: data.type,
      officeSize: data.size,
      agreation: {
        type: data.type,
        number: data.approval.replace(/[ .]/g, ""),
      },
      isOeccbbMember,
    })
      .then((resp) => {
        setLoader(false);
        if (resp.data.result === "OK") {
          dispatch(setFiduciary(resp.data.data));
          toast.success(_("successfully_added"));
          isOeccbbMember ? navigate("/join") : navigate("/pending");
        }
      })
      .catch((e) => {
        setLoader(false);
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.length > 0
        )
          toast.error(_(e.response.data.errors[0].message));
        else toast.error(_("error"));
      });
  };

  useEffect(() => {
    // async function fetchClientToken() {
    //   const resp = await getClientCredential();
    //   const helpResp = typeof resp === "string" ? JSON.parse(resp) : resp;
    //   const token = helpResp.token.access_token;
    //   dispatch(setClientToken(token));
    // }
    // fetchClientToken();
    dispatch(fetchClientToken());

    setLoaderMembership(true);
    getUserHasFiduciaryPlan(auth.token)
      .then((resp) => {
        if (resp.data.data.hasFiduciary) {
          setIsOeccbbMember(true);
          let size = "5-8";
          if (resp.data.data.size > 1) {
            const beginSize = resp.data.data.size * 4 + 1;
            size = beginSize + "-" + (beginSize + 3);
          }
          setData({
            ...data,
            uen: formatUen(resp.data.data.billingCompanyNumber),
            name: resp.data.data.billingOrganization,
            address1: resp.data.data.billingStreet,
            zipCode: resp.data.data.billingPostalCode,
            city: resp.data.data.billingCountry,
            size,
          });
        }
      })
      .catch((e) => {})
      .finally(() => {
        setLoaderMembership(false);
      });
  }, []);

  const getSizeText = () => {
    let titleText = _("title_office_step_2");
    const size = data.size.split("-");
    titleText = titleText.replace("%1%", size[0]);
    return titleText.replace("%2%", size[1]);
  };

  if (loaderMembership) {
    return <div className="lmask"></div>;
  }
  return (
    <>
      {loader && <div className="lmask"></div>}
      <div className={`grid-container ${styles.endSpace}`}>
        <div className={styles.title}>
          {step === 1 ? _(`title_office_step_1`) : getSizeText()}
        </div>
        <div className={styles.subTitle}>
          {_(`subTitle_office_step_${step}`)}
        </div>

        {step === 1 && (
          <StepOne
            setData={setData}
            data={data}
            errors={errors}
            setErrors={setErrors}
            isExistFiduciary={isExistFiduciary}
            setIsExistFiduciary={setIsExistFiduciary}
            isOeccbbMember={isOeccbbMember}
          />
        )}
        {step === 2 && <StepTwo data={data} />}
      </div>

      <div className={styles.fixedBar}>
        <div className="grid-container">
          {step === 1 ? (
            <span></span>
          ) : (
            <Button
              onClick={clickPrev}
              variant="default"
              icon="ttp-double-arrow-left"
            >
              {_("retour").toUpperCase()}
            </Button>
          )}

          {(step === 2 || isOeccbbMember) && (
            <div className={styles.actionCheck}>
              <Checkbox checked={isAccepted} onClick={handleCheckboxClick} />
              <label
                className={styles.labelcheckbox}
                onClick={handleCheckboxClick}
              >
                J'ai lu et j'accepte{" "}
                <a
                  target="_blank"
                  href="https://help.tamtam.pro/fr/privacy/terms_of_use"
                  className={styles.link}
                >
                  <span>les conditions d'utilisation</span>
                </a>
              </label>
            </div>
          )}

          <Button
            onClick={clickNext}
            variant="primary"
            iconRight={step === 1 ? "ttp-double-arrow-right" : ""}
            disabled={(step === 2 || isOeccbbMember) && !isAccepted}
          >
            {step === 2 || isOeccbbMember
              ? _("validate_membership").toUpperCase()
              : _("continue").toUpperCase()}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CreateOffice;
